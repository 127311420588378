import { React, useState, useEffect } from 'react';
import { useQuery } from 'urql';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ReactGA from "react-ga";

const ActualsQuery = `
query($slug: String!){
    news(where:{slug: $slug}){
        title
        slug
        content{
            html
        }
        createdBy {
            name
        }
        dodano
        photos {
            url
        }
    }
}

`;

const Actual = () => {
    ReactGA.send({ hitType: "pageview", page: "/aktualnosc", title: "Aktualnośc" });

    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
    const [photosUrls, setPhotosUrls] = useState([]);

    const param = useParams();
    const slug = param.slug;

    const [result] = useQuery({
        query: ActualsQuery,
        variables: {
            slug
        }
    })

    const { data, fetching, err } = result;

    useEffect(() => {
        if (data?.news[0]?.photos) {
            const array = data?.news[0].photos.map((obj, index) => {
                return {
                    id: index,
                    original: obj.url
                }
            })
            setPhotosUrls(array)
        }
    }, [data])

    if (fetching) return <p>Pobieram informacje z wpisu...</p>
    if (err) return <p>O nie... {err.message}</p>

    return (
        <>
            {isDesktopOrLaptop &&
                <div style={{ marginTop: "10px", width: "100%", backgroundColor: "white", borderRadius: "11px" }}>
                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <div style={{ marginLeft: "30px", marginRight: "30px", width: "100%" }}>
                            {ReactHtmlParser(data?.news[0]?.content.html)}
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", paddingBottom: "11px" }}>
                        {data?.news[0]?.photos.length === 1 && <img style={{ width: "500px", height: "600px", marginBottom: "11px", paddingLeft: "11px", paddingRight: "11px" }} src={data?.news[0]?.photos[0].url} alt="aktualnosc" />}
                        {data?.news[0]?.photos.length > 1 &&
                            <ImageGallery
                                items={photosUrls}
                                showFullscreenButton={false}
                                showBullets={true}
                                showPlayButton={false}
                            />
                        }
                        {data?.news[0]?.photos.length === 0 && null}
                    </div>
                </div>
            }
            {isTabletOrMobile &&
                <div className="flex justify-content-center" style={{ paddingLeft: "20px", marginTop: "10px", width: "100%", flexDirection: "column" }}>

                    <div style={{ width: "100%" }}>
                        {ReactHtmlParser(data?.news[0]?.content.html)}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {data?.news[0]?.photos.length === 1 && <img style={{ width: "100%", height: "70%" }} src={data?.news[0]?.photos[0].url} alt="aktualnosc" />}
                        {data?.news[0]?.photos.length > 1 &&
                            <ImageGallery
                                items={photosUrls}
                                showFullscreenButton={false}
                                showBullets={true}
                                showPlayButton={false}
                            />
                        }
                        {data?.news[0]?.photos.length === 0 && null}
                    </div>
                </div>
            }
        </>
    )
}

export default Actual;